<template>
	<div>
		<h3>{{ groupInfo.name }} / {{ groupInfo.group_name }} / {{ groupInfo.hour_name }} / {{ groupInfo.lang_name }} / {{ groupInfo.ages }} лет</h3>
	
		<v-divider class="my-4"></v-divider>
		
		<v-data-table
			:headers="requestHeaders"
			:items="requestList"
			:items-per-page="15"
			:loading="isLoading"
			@click:row="handleClick"
			class="elevation-1 request_list">
		</v-data-table>
	</div>
</template>

<script>
	export default {
        mounted() {
            this.getData();
        },
		data: () => ({
			isLoading: true,
			groupInfo: {},
			requestHeaders: [],
			requestList: []
		}),
		methods: {
			handleClick(item) {
				this.$router.push({ path: '/department/kindergarten/'+this.$route.params.id+'/group/'+item.id });
			},
			async getData() {
				await this.$http.get('/kindergarten/'+this.$route.params.id+'/group/'+this.$route.params.groupId).then((response) => {
					this.requestHeaders = response.data.data.kindergartenGroupFreeSeatsList.headers;
					this.requestList = response.data.data.kindergartenGroupFreeSeatsList.list;
					this.groupInfo = response.data.data.kindergartenGroupInfo;
					this.isLoading = false;
				});
			}
			
		}
  }
</script>

<style scoped>

</style>